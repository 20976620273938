import React, { useState } from "react";
import { StateMachineProvider, createStore } from "little-state-machine";

createStore({
  data: {},
});

export const modalContext = React.createContext();

export const ModalProvider = (props) => {
  const [freeTrialModalOpen, setFreeTrialModalOpen] = useState(false);
  const [newsletterModalOpen, setNewsletterModalOpen] = useState(false);
  const [
    preTrainingQuestionnaireModalOpen,
    setPreTrainingQuestionnaireModalOpen,
  ] = useState(false);
  const [selectedLocationSlug, setSelectedLocationSlug] = useState(null);
  const [workoutModalOpen, setWorkoutModalOpen] = useState(false);
  const [workoutModalData, setWorkoutModalData] = useState({});
  const [trainerModalOpen, setTrainerModalOpen] = useState(false);
  const [trainerModalData, setTrainerModalData] = useState({});
  const [tcsModalOpen, setTcsModalOpen] = useState(false);
  const [preFillData, setPreFillData] = useState({});
  const [jobApplicationModalOpen, setJobApplicationModalOpen] = useState(false);
  const [selectedJobSlug, setSelectedJobSlug] = useState(null);

  return (
    <modalContext.Provider
      value={{
        freeTrialModalOpen,
        setFreeTrialModalOpen: (open) => setFreeTrialModalOpen(open),
        openFreeTrialModal: () => setFreeTrialModalOpen(true),
        newsletterModalOpen,
        setNewsletterModalOpen: (open) => setNewsletterModalOpen(open),
        openNewsletterModal: () => setNewsletterModalOpen(true),
        preFillData,
        setPreFillData: (data) => setPreFillData(data),
        preTrainingQuestionnaireModalOpen,
        setPreTrainingQuestionnaireModalOpen: (open) =>
          setPreTrainingQuestionnaireModalOpen(open),
        openPreTrainingQuestionnaireModal: () =>
          setPreTrainingQuestionnaireModalOpen(true),
        workoutModalOpen,
        setWorkoutModalOpen: (open) => {
          setWorkoutModalOpen(open);
          if (!open) {
            setTimeout(() => {
              setWorkoutModalData({});
            }, 200);
          }
        },
        openWorkoutModalOpen: (workout) => {
          setWorkoutModalOpen(true);
          setWorkoutModalData(workout);
        },
        workoutModalData,
        trainerModalOpen,
        setTrainerModalOpen: (open) => {
          setTrainerModalOpen(open);
          if (!open) {
            setTimeout(() => {
              setTrainerModalData({});
            }, 200);
          }
        },
        openTrainerModalOpen: (trainer) => {
          setTrainerModalOpen(true);
          setTrainerModalData(trainer);
        },
        trainerModalData,
        selectedLocationSlug,
        setSelectedLocationSlug: (locationSlug) =>
          setSelectedLocationSlug(locationSlug),
        tcsModalOpen,
        setTcsModalOpen,
        jobApplicationModalOpen,
        setJobApplicationModalOpen: (open) => setJobApplicationModalOpen(open),
        openJobApplicationModal: () => setJobApplicationModalOpen(true),
        selectedJobSlug,
        setSelectedJobSlug: (jobSlug) => setSelectedJobSlug(jobSlug),
      }}
    >
      {props.children}
    </modalContext.Provider>
  );
};

export const useModal = () => React.useContext(modalContext);

const Provider = ({ element }) => (
  <StateMachineProvider>
    <ModalProvider>{element}</ModalProvider>
  </StateMachineProvider>
);
export default Provider;
